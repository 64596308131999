import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Analytics service for tracking user interactions with the site
 * Uses localStorage to maintain client ID across sessions
 */

/**
 * Generate a client ID if one doesn't exist yet
 * @returns {string} Client ID
 */
const getClientId = () => {
  // Try to get existing client ID from localStorage
  let clientId = localStorage.getItem('client_id')
  
  // If no client ID exists, generate one
  if (!clientId) {
    // Generate a random client ID
    clientId = Math.random().toString(36).substring(2, 15)
    localStorage.setItem('client_id', clientId)
  }
  
  return clientId
}

/**
 * Track an event with the analytics backend
 * @param {string} eventName - Name of the event to track
 * @param {string} pageLocation - Current page URL
 * @param {string} linkUrl - Optional URL for link clicks
 * @param {Object} extraData - Optional additional data to include
 * @returns {Promise<boolean>} Success status
 */
export const trackEvent = async (eventName, pageLocation, linkUrl = '', extraData = {}) => {
  try {
    // Skip tracking for localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      console.log('Analytics tracking skipped on localhost');
      return true;
    }
    
    const clientId = getClientId()
    
    // Determine if we're running locally or in production
    const isLocalhost = window.location.hostname === 'localhost' || 
                        window.location.hostname === '127.0.0.1';
    
    // Set the correct endpoint based on environment
    const endpoint = isLocalhost 
      ? 'http://localhost:3001/track-event'  // Use direct URL in development
      : '/track-event';                       // Use relative URL in production
    
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client_id: clientId,
        event_name: eventName,
        page_location: pageLocation,
        link_url: linkUrl,
        ...extraData
      }),
      // Don't wait for response to complete
      keepalive: true
    })
    
    if (!response.ok) {
      throw new Error(`Server error ${response.status}`)
    }
    
    const data = await response.json()
    console.log('Event tracked successfully:', data)
    return true
  } catch (error) {
    console.error('Error tracking event:', error)
    // Silently fail - analytics should never break the app
    return false
  }
}

/**
 * Record a page view event
 * @param {string} pageLocation - Current page URL
 * @param {Array<string>} tags - Optional tags for the event
 */
export const recordPageView = (pageLocation, tags = []) => {
  return trackEvent('page_view', pageLocation, '', { tags })
}

/**
 * Record an image view event
 * @param {string} pageLocation - Current page URL
 * @param {number} imageId - ID of the image being viewed
 * @param {Array<string>} tags - Optional tags for the event
 */
export const recordImageView = (pageLocation, imageId, tags = []) => {
  return trackEvent('image_view', pageLocation, '', { image_id: imageId, tags })
}

/**
 * Record a link click event
 * @param {string} pageLocation - Current page URL
 * @param {string} linkUrl - URL of the clicked link
 * @param {Array<string>} tags - Optional tags for the event
 */
export const recordLinkClick = (pageLocation, linkUrl, tags = []) => {
  return trackEvent('link_click', pageLocation, linkUrl, { tags })
}

/**
 * Custom hook to track page views
 */
export const usePageTracking = () => {
  const location = useLocation()
  
  useEffect(() => {
    // Only track page_view if pathname changed, not just the #hash
    if (location.hash) {
      // Skip tracking for hash changes
      return
    }
    
    recordPageView(window.location.href)
    
    // No cleanup needed for page view tracking
  }, [location.pathname, location.search])
}

// Create a named export object
const analyticsService = {
  trackEvent,
  recordPageView,
  recordImageView,
  recordLinkClick,
  usePageTracking
}

export default analyticsService 