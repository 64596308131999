import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import './Gallery.css';

// SVG Icons as components
const ViewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
    <circle cx="12" cy="12" r="3"></circle>
  </svg>
);

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="7 10 12 15 17 10"></polyline>
    <line x1="12" y1="15" x2="12" y2="3"></line>
  </svg>
);

const NewTabIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
  </svg>
);

// SVG Icons as components with animation classes
const SortAscIcon = () => (
  <svg className="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="19 12 12 19 5 12"></polyline>
  </svg>
);

const SortDescIcon = () => (
  <svg className="sort-icon active" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="12" y1="19" x2="12" y2="5"></line>
    <polyline points="5 12 12 5 19 12"></polyline>
  </svg>
);

const ExpandIcon = () => (
  <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const CollapseIcon = () => (
  <svg className="expand-icon active" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const Gallery = ({ trackEvent }) => {
  const {
    drawingsToShow = [],
    activeTags = [],
    tagLogicAnd = false,
    hideUnmatched = false,
    loading,
    toggleTag,
    totalNonExcluded = 0,
    matchCount = 0,
  } = useOutletContext() || {};

  const [modalData, setModalData] = useState(null);
  const [collapsedYears, setCollapsedYears] = useState({});
  const [yearOrderAsc, setYearOrderAsc] = useState(false);
  const [areAllCollapsed, setAreAllCollapsed] = useState(false);
  const [isAdultConfirmed, setIsAdultConfirmed] = useState(
    () => localStorage.getItem('isAdultConfirmed') === 'true'
  );
  const [copied, setCopied] = useState(false);
  const [sortBy, setSortBy] = useState('date'); // 'date', 'views'

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  useEffect(() => {
    if (!drawingsToShow.length) return;
    const params = new URLSearchParams(window.location.search);
    const imageId = params.get('image');
    if (imageId) {
      const maybeId = parseInt(imageId, 10);
      if (!isNaN(maybeId)) {
        const found = drawingsToShow.find((dw) => dw.id === maybeId);
        if (found) setModalData(found);
      }
    }
  }, [drawingsToShow]);

  // Group drawings by year
  const drawingsByYear = drawingsToShow.reduce((acc, dw) => {
    const year = new Date(dw.draw_date).getFullYear();
    if (!acc[year]) acc[year] = [];
    acc[year].push(dw);
    return acc;
  }, {});

  // Sort drawings within each year
  Object.keys(drawingsByYear).forEach(year => {
    drawingsByYear[year].sort((a, b) => {
      if (sortBy === 'views') {
        return (b.view_count || 0) - (a.view_count || 0);
      } else {
        const dateA = new Date(a.draw_date);
        const dateB = new Date(b.draw_date);
        return yearOrderAsc ? dateA - dateB : dateB - dateA;
      }
    });
  });

  const sortedYears = Object.keys(drawingsByYear).sort((a, b) =>
    yearOrderAsc ? a - b : b - a
  );

  // Initialize all years as expanded when the component loads
  useEffect(() => {
    if (Object.keys(drawingsByYear).length > 0 && Object.keys(collapsedYears).length === 0) {
      const initialCollapsedState = {};
      Object.keys(drawingsByYear).forEach((yr) => {
        initialCollapsedState[yr] = false; // Set to false to expand all years by default
      });
      setCollapsedYears(initialCollapsedState);
    }
  }, [drawingsByYear]);

  const toggleExpandCollapse = () => {
    const newVal = !areAllCollapsed;
    const newObj = {};
    Object.keys(drawingsByYear).forEach((yr) => {
      newObj[yr] = newVal;
    });
    setCollapsedYears(newObj);
    setAreAllCollapsed(newVal);
  };

  // Dimming logic: only if in dimming mode and activeTags exist.
  const isDimmed = (dw) => {
    if (hideUnmatched || !activeTags.length) return false;
    return tagLogicAnd
      ? !activeTags.every((t) => dw.tags.includes(t))
      : !dw.tags.some((t) => activeTags.includes(t));
  };

  const handleImageClick = (dw) => {
    setModalData(dw);
    const params = new URLSearchParams(window.location.search);
    params.set('image', dw.id);
    window.history.replaceState(
      null,
      '',
      window.location.pathname + '?' + params.toString()
    );
    
    // Skip tracking for localhost
    if (trackEvent && dw.id && !window.location.hostname.includes('localhost')) {
      trackEvent('image_view', window.location.href, '', { image_id: dw.id });
    }
  };

  const handleCloseModal = () => {
    setModalData(null);
    const params = new URLSearchParams(window.location.search);
    params.delete('image');
    window.history.replaceState(null, '', window.location.pathname + '?' + params.toString());
  };

  // Get all drawings in a flat array, sorted by date
  const allDrawingsSortedByDate = useMemo(() => {
    return [...drawingsToShow].sort((a, b) => {
      const dateA = new Date(a.draw_date);
      const dateB = new Date(b.draw_date);
      return dateB - dateA; // Newest first by default
    });
  }, [drawingsToShow]);

  // Find previous and next drawings based on current modal data
  const getAdjacentDrawings = () => {
    if (!modalData || !allDrawingsSortedByDate.length) return { prev: null, next: null };
    
    const currentIndex = allDrawingsSortedByDate.findIndex(d => d.id === modalData.id);
    if (currentIndex === -1) return { prev: null, next: null };
    
    // Swap prev and next to match chronological order
    // prev = newer (more recent) drawing, next = older drawing
    const next = currentIndex < allDrawingsSortedByDate.length - 1 ? allDrawingsSortedByDate[currentIndex + 1] : null;
    const prev = currentIndex > 0 ? allDrawingsSortedByDate[currentIndex - 1] : null;
    
    return { prev, next };
  };

  const { prev, next } = getAdjacentDrawings();

  const navigateToDrawing = (drawing) => {
    if (!drawing) return;
    
    setModalData(drawing);
    const params = new URLSearchParams(window.location.search);
    params.set('image', drawing.id);
    window.history.replaceState(
      null,
      '',
      window.location.pathname + '?' + params.toString()
    );
    
    // Skip tracking for localhost
    if (trackEvent && drawing.id && !window.location.hostname.includes('localhost')) {
      trackEvent('image_view', window.location.href, '', { image_id: drawing.id });
    }
  };

  // Add keyboard navigation function
  const handleKeyDown = (e) => {
    if (!modalData) return;
    
    switch (e.key) {
      case 'Escape':
        handleCloseModal();
        break;
      case 'ArrowLeft':
        if (prev) {
          // Simulate button press on prev button
          const prevButton = document.querySelector('.prev-button');
          if (prevButton) {
            prevButton.classList.add('button-press');
            setTimeout(() => prevButton.classList.remove('button-press'), 300);
          }
          navigateToDrawing(prev);
        }
        break;
      case 'ArrowRight':
        if (next) {
          // Simulate button press on next button
          const nextButton = document.querySelector('.next-button');
          if (nextButton) {
            nextButton.classList.add('button-press');
            setTimeout(() => nextButton.classList.remove('button-press'), 300);
          }
          navigateToDrawing(next);
        }
        break;
      default:
        // Do nothing for other keys
        break;
    }
  };

  // Add useEffect for keyboard event listener
  useEffect(() => {
    if (modalData) {
      // Add keyboard event listener when modal is open
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        // Remove event listener when component unmounts or modal closes
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [modalData, prev, next]);

  if (loading) return <LoadingSpinner />;

  if (!isAdultConfirmed) {
    return (
      <div className="gallery-modal-overlay">
        <div className="confirmation-modal">
          <h2>Content Advisory</h2>
          <p>You must be 18+ to view this gallery.</p>
          <button
            className="primary-button"
            onClick={() => {
              setIsAdultConfirmed(true);
              localStorage.setItem('isAdultConfirmed', 'true');
            }}
          >
            I am a legal adult
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="gallery-content">
      <h1 className="gallery-title">Gallery</h1>
      <div className="gallery-controls">
        <div className="control-group">
          <button 
            className={`control-button ${yearOrderAsc ? 'active' : ''}`}
            onClick={() => setYearOrderAsc(!yearOrderAsc)}
          >
            <span className="button-icon">
              {yearOrderAsc ? <SortAscIcon /> : <SortDescIcon />}
            </span>
            {yearOrderAsc ? 'Oldest First' : 'Newest First'}
          </button>
          <button 
            className={`control-button ${areAllCollapsed ? 'active' : ''}`}
            onClick={toggleExpandCollapse}
          >
            <span className="button-icon">
              {areAllCollapsed ? <ExpandIcon /> : <CollapseIcon />}
            </span>
            {areAllCollapsed ? 'Expand All' : 'Collapse All'}
          </button>
        </div>
        <div className="sort-controls">
          <span className="sort-label">Sort by:</span>
          <button 
            className={`sort-button ${sortBy === 'date' ? 'active' : ''}`}
            onClick={() => setSortBy('date')}
          >
            Date
          </button>
          <button 
            className={`sort-button ${sortBy === 'views' ? 'active' : ''}`}
            onClick={() => setSortBy('views')}
          >
            Views
          </button>
        </div>
      </div>

      {Object.keys(drawingsByYear).length === 0 ? (
        <div className="no-results">
          <p>No drawings found.</p>
        </div>
      ) : (
        sortedYears.map((year) => {
          const yearDrawings = drawingsByYear[year];

          return (
            <div key={year} className="year-group">
              <div
                className={`year-title ${collapsedYears[year] ? 'collapsed' : ''}`}
                onClick={() => {
                  setCollapsedYears({
                    ...collapsedYears,
                    [year]: !collapsedYears[year],
                  });
                }}
              >
                {year}
                <span className="year-toggle">
                  {collapsedYears[year] ? <ExpandIcon /> : <CollapseIcon />}
                </span>
              </div>
              {!collapsedYears[year] && (
                <div className="gallery-grid">
                  {yearDrawings.map((dw, index) => {
                    const isGif = dw.file_name.toLowerCase().endsWith('.gif');
                    return (
                      <div
                        key={dw.id}
                        className={`gallery-item ${isDimmed(dw) ? 'dimmed' : ''} ${
                          dw.tags.includes('pending') ? 'pending-border' : ''
                        }`}
                      >
                        <div
                          className="thumbnail-container"
                          onClick={() => handleImageClick(dw)}
                        >
                          <img
                            src={`https://chaptude.com/thumbnails/${year}/${dw.file_name}`}
                            alt={dw.description || 'Drawing'}
                            className="gallery-thumbnail"
                            loading="lazy"
                          />
                          <div className="view-count">
                            <ViewIcon />
                            <span className="view-number">{dw.view_count || 0}</span>
                          </div>
                          {isGif && <div className="gif-indicator">GIF</div>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })
      )}
      {modalData && (
        <div className="gallery-modal-overlay" onClick={handleCloseModal}>
          <div
            className="gallery-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="modal-close-button" onClick={handleCloseModal}>
              <CloseIcon />
            </button>
            
            <div className="modal-nav-container">
              {prev && (
                <button 
                  className="modal-nav-button prev-button" 
                  onClick={() => navigateToDrawing(prev)}
                  aria-label="Newer image"
                >
                  <span className="nav-button-icon">&#10094;</span>
                  <span className="nav-button-text">Newer</span>
                </button>
              )}
              
              {!prev && (
                <div className="modal-nav-placeholder"></div>
              )}
              
              {next && (
                <button 
                  className="modal-nav-button next-button" 
                  onClick={() => navigateToDrawing(next)}
                  aria-label="Older image"
                >
                  <span className="nav-button-text">Older</span>
                  <span className="nav-button-icon">&#10095;</span>
                </button>
              )}
              
              {!next && (
                <div className="modal-nav-placeholder"></div>
              )}
            </div>
            
            <div className="modal-top">
              <div className="modal-image-container">
                <img
                  className="gallery-modal-image"
                  src={`https://chaptude.com/gallery/${new Date(
                    modalData.draw_date
                  ).getFullYear()}/${modalData.file_name}`}
                  alt={modalData.description || 'Drawing'}
                />
              </div>
              <div className="modal-metadata">
                <h3>{modalData.file_name}</h3>
                <div className="modal-stats">
                  <div className="stat-item">
                    <ViewIcon />
                    <span className="stat-value">{modalData.view_count || 0} views</span>
                  </div>
                  <div className="stat-item">
                    <span className="stat-value">
                      {new Date(modalData.draw_date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </span>
                  </div>
                </div>
                <div className="modal-description">
                  {(modalData.description || '')
                    .split('\n')
                    .map((line, idx) => (line.trim() ? <p key={idx}>{line}</p> : <br key={idx} />))}
                </div>
                <div className="tags-section">
                  {modalData.tags.map((tag) => (
                    <span
                      key={tag}
                      className={`modal-tag ${activeTags.includes(tag) ? 'active' : ''}`}
                      onClick={() => toggleTag && toggleTag(tag)}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="modal-actions">
                  <button
                    className="action-button download-button"
                    onClick={() => {
                      if (trackEvent && modalData.id && !window.location.hostname.includes('localhost')) {
                        trackEvent('image_download', window.location.href, '', {
                          image_id: modalData.id,
                        });
                      }
                      const link = document.createElement('a');
                      link.href = `https://chaptude.com/gallery/${new Date(
                        modalData.draw_date
                      ).getFullYear()}/${modalData.file_name}`;
                      link.download = modalData.file_name;
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                  >
                    <DownloadIcon />
                    <span>Download</span>
                  </button>
                  <button
                    className="action-button open-tab-button"
                    onClick={() => {
                      if (trackEvent && modalData.id && !window.location.hostname.includes('localhost')) {
                        trackEvent(
                          'image_open_new_tab',
                          window.location.href,
                          '',
                          { image_id: modalData.id }
                        );
                      }
                      window.open(
                        `https://chaptude.com/gallery/${new Date(
                          modalData.draw_date
                        ).getFullYear()}/${modalData.file_name}`,
                        '_blank'
                      );
                    }}
                  >
                    <NewTabIcon />
                    <span>Open in New Tab</span>
                  </button>
                  <button
                    className={`action-button copy-button ${copied ? 'copied' : ''}`}
                    onClick={() => {
                      const currentURL = window.location.href;
                      navigator.clipboard.writeText(currentURL);
                      if (trackEvent && modalData.id && !window.location.hostname.includes('localhost')) {
                        trackEvent('image_copy_link', currentURL, '', {
                          image_id: modalData.id,
                        });
                      }
                      setCopied(true);
                    }}
                  >
                    <LinkIcon />
                    <span>{copied ? 'Copied!' : 'Copy Link'}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
