import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages from new locations
import Home from './pages/Home';
import Links from './pages/Links';
import Gallery from './pages/Gallery';
import Commission from './pages/Commission';
import NotFound from './pages/NotFound';

// Import Layout from new location
import Layout from './components/Layout';

// Import analytics service
import { usePageTracking, trackEvent } from './services/analyticsService';

function App() {
  // Use the page tracking hook
  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="links" element={<Links trackEvent={trackEvent} />} />
        <Route path="gallery" element={<Gallery trackEvent={trackEvent} />} />
        <Route path="commission" element={<Commission />} /> 
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
} 