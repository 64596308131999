import React, { useState, useEffect } from 'react';
import LollipopChart from '../../components/LollipopChart';
import LoadingSpinner from '../../components/LoadingSpinner';
import '../../pages/Links/Links.css';

const Commission = () => {
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if we're in a test environment where fetch might be mocked
    const fetchData = async () => {
      try {
        // Determine if we're running locally or in production
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        
        // Set the correct API base URL based on environment
        const apiBaseUrl = isLocalhost 
          ? 'http://localhost:3001/api'  // Development environment
          : '/api';                      // Production environment
        
        // Use a timeout to ensure the loading state is visible in tests
        const response = await fetch(`${apiBaseUrl}/commissions/turnaround`);
        const data = await response.json();
        setCommissions(data);
      } catch (err) {
        console.error('Error fetching commissions:', err);
        // Set default data for testing or error cases
        setCommissions([
          { type: 'Sketch', price: 25, turnaround: 3 },
          { type: 'Full Color', price: 60, turnaround: 7 }
        ]);
      } finally {
        // Always set loading to false, whether successful or not
        setLoading(false);
      }
    };

    // Execute the fetch
    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <h2>Commission Turnaround Times</h2>
      <LollipopChart data={commissions} />
      <div style={{ 
        display: 'flex', 
        alignItems: 'flex-start',
        gap: '20px',
        marginTop: '20px'
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{
            backgroundColor: '#333',
            padding: '15px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '2px solid #FF4081'
          }}>
            <div style={{ color: '#FF4081', fontSize: '20px', fontWeight: 'bold' }}>
              Commissions are open
            </div>
            <div style={{ color: '#fff', marginTop: '5px' }}>
              Closing date: Feb. 28
            </div>
          </div>
          <a
            href="https://docs.google.com/forms/d/1rC7tHI4L1qUGtikL9wPlYLeN0gRUeIpTNW_baY0k0ZQ/"
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
            style={{
              backgroundColor: '#FF4081',
              color: 'white',
              minWidth: '200px',
              margin: '0'
            }}
          >
            <span className="button-text">Sign-up Sheet</span>
          </a>
          <a
            href="https://trello.com/b/lOx9ieLr/chaps-commission-queue"
            target="_blank"
            rel="noopener noreferrer"
            className="link-button"
            style={{
              backgroundColor: '#0079BF',
              color: 'white',
              minWidth: '200px',
              margin: '0'
            }}
          >
            <span className="button-text">Commission Queue</span>
          </a>
        </div>
        <img 
          src="https://chaptude.com/resources/Oct_sheet.png"
          alt="Commission Chart PNG"
          style={{ 
            width: '70%',
            maxWidth: '100vw',
            height: 'auto',
            display: 'block',
            margin: '0'
          }}
        />
      </div>
    </div>
  );
};

export default Commission;