import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const LollipopChart = ({ data }) => {
  const svgRef = useRef(null);

  // Dimensions
  const width = 1200;
  const height = 500;
  const margin = { top: 70, right: 40, bottom: 60, left: 80 };

  useEffect(() => {
    if (!data || data.length === 0) return;

    const today = new Date();

    // 1) Prep data
    data.forEach(d => {
      d.startDateObj = new Date(d.start_date);
      if (!d.end_date) {
        d.endDateObj = today;
        d.isWip = true;
      } else {
        d.endDateObj = new Date(d.end_date);
        d.isWip = false;
      }
      if (!d.image_id || d.image_id.trim() === '') {
        d.image_id = null;
      }
      d.turnaround_days = Math.round(
        (d.endDateObj - d.startDateObj) / (1000 * 3600 * 24)
      );
      d.turnaround_days_original = d.turnaround_days;
      if (d.turnaround_days > 200) {
        d.turnaround_days = 200;
        d.isOutlier = true;
      } else {
        d.isOutlier = false;
      }
    });

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => d.startDateObj))
      .range([margin.left, width - margin.right]);

    const yMax = 200;
    const yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([height - margin.bottom, margin.top]);

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    svg.selectAll('*').remove();

    // Legend
    const legendData = [
      { color: '#FFAC81', label: '20+ Days' },
      { color: '#C62E51', label: 'Normal' },
      { color: '#666', label: 'Outlier Stem' },
      { color: '#E5A3FF', label: 'WIP' }
    ];

    const legend = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top - 50})`);

    legend
      .selectAll('rect')
      .data(legendData)
      .enter()
      .append('rect')
      .attr('x', (_, i) => i * 150)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', d => d.color);

    legend
      .selectAll('text')
      .data(legendData)
      .enter()
      .append('text')
      .attr('x', (_, i) => i * 150 + 30)
      .attr('y', 15)
      .text(d => d.label)
      .style('fill', 'white')
      .style('font-size', '14px')
      .style('alignment-baseline', 'middle');

    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%b %Y'));
    svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    const yAxis = d3.axisLeft(yScale).ticks(6);
    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis);

    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', height - 20)
      .attr('text-anchor', 'middle')
      .text('Date')
      .style('font-size', '16px')
      .style('fill', 'white');

    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .text('Turnaround - Days')
      .style('font-size', '16px')
      .style('fill', 'white');

    svg
      .append('line')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', yScale(20))
      .attr('y2', yScale(20))
      .attr('stroke', '#20A4F3')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '6 5');

    svg
      .append('line')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', yScale(180))
      .attr('y2', yScale(180))
      .attr('stroke', '#FF7D62')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '6 5');

    const tooltip = d3
      .select('#lollipop-tooltip')
      .style('position', 'absolute')
      .style('padding', '6px')
      .style('background', 'rgba(0,0,0,0.7)')
      .style('color', '#fff')
      .style('border-radius', '4px')
      .style('font-size', '0.85rem')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    svg
      .selectAll('.stem')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'stem')
      .attr('x1', d => xScale(d.startDateObj))
      .attr('x2', d => xScale(d.startDateObj))
      .attr('y1', yScale(0))
      .attr('y2', yScale(0))
      .attr('stroke', d => (d.isOutlier ? '#666' : '#FFFFFF'))
      .attr('stroke-width', 1)
      .transition()
      .duration(200)
      .delay((_, i) => i * 10)
      .attr('y2', d => yScale(d.turnaround_days));

    svg
      .selectAll('.lollipop-head')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'lollipop-head')
      .attr('cx', d => xScale(d.startDateObj))
      .attr('cy', yScale(0))
      .attr('r', d => 7)
      .attr('fill', d => (d.isWip ? '#E5A3FF' : d.turnaround_days > 20 ? '#FFAC81' : '#C62E51'))
      .attr('stroke', '#04101A')
      .attr('stroke-width', 1)
      .on('mouseover', (event, d) => {
        tooltip
          .html(`
            <div style="margin-bottom: 8px;">
              <strong>Image ID: ${d.image_id ?? '[No ID]'}</strong>
            </div>
            <div>Start: ${d3.timeFormat('%m/%d/%Y')(d.startDateObj)}</div>
            <div>End: ${d.isWip
              ? 'In Progress'
              : d3.timeFormat('%m/%d/%Y')(d.endDateObj)
            }</div>
            <div>Days: ${d.isOutlier ? `200+ (${d.turnaround_days_original})` : d.turnaround_days}</div>
            ${d.image_id
              ? `<img
                     src="https://chaptude.com/thumbnails/${d.image_id}.jpg"
                     alt="thumb"
                     width="100"
                     style="margin-top: 8px; border: 1px solid #fff;"
                   />`
              : ''
            }
          `)
          .style('left', event.pageX + 280 + 'px')
          .style('top', event.pageY + 60 + 'px')
          .transition()
          .duration(150)
          .style('opacity', 1);
      })
      .on('mousemove', event => {
        tooltip
          .style('left', event.pageX - 270 + 'px')
          .style('top', event.pageY - 60 + 'px');
      })
      .on('mouseout', () => {
        tooltip
          .transition()
          .duration(150)
          .style('opacity', 0);
      })
      .on('click', (event, d) => {
        if (d.image_id) {
          window.open(`https://chaptude.com/gallery#image-${d.image_id}`, '_blank');
        }
      })
      .transition()
      .duration(200)
      .delay((_, i) => i * 10)
      .attr('cy', d => yScale(d.turnaround_days));
  }, [data]);

  return (
    <div style={{ position: 'relative' }}>
      <svg ref={svgRef}></svg>
      <div id="lollipop-tooltip"></div>
    </div>
  );
};

export default LollipopChart;